<template>
  <div :class="getIframeContainerClass">
    <div :class="getIframeActionsClass">
      <h1 class="heading heading-m-small iframe-title white--text">
        {{ getIframeTitle }}
      </h1>
      <button
        v-show="isExpanded"
        class="iframe-action pa-2"
        @click="handleShow"
      >
        <MinusIconComponent />
      </button>
      <button
        v-show="!isExpanded"
        class="iframe-action px-2"
        @click="handleShow"
      >
        <ExpandIconComponent />
      </button>
      <button
        v-show="isExpanded"
        class="iframe-action px-2"
        @click="handleClose"
      >
        <CloseIconComponent />
      </button>
    </div>
    <iframe
      v-show="showIframe"
      id="test"
      title="article"
      :class="getIframeClass"
      :src="iframe_article_path"
      allowfullscreen
      width="100%"
    />
  </div>
</template>

<script>
import MinusIconComponent from "@/assets/MinusIconComponent";
import CloseIconComponent from "@/assets/CloseIconComponent";
import ExpandIconComponent from "@/assets/ExpandIconComponent";
export default {
  components: { MinusIconComponent, CloseIconComponent, ExpandIconComponent },
  props: ['show', 'path'],
  data: () => ({
    iframe_article_path: null,
    isExpanded: false,
    iframeReady: false,
  }),
  computed: {
    getIframeTitle(){
      return this.isExpanded ? "Article" : "Expand Article";
    },
    showIframe() {
      return this.show && this.isExpanded;
    },
    getIframeContainerClass() {
      return this.isExpanded ? 'iframe-container' : '';
    },
    getIframeClass() {
      return this.isExpanded ? 'iframe' : 'iframe-shrinked';
    },
    getIframeActionsClass() {
      return this.isExpanded ? 'iframe-actions px-3': 'iframe-actions px-3 iframe-actions-sticky';
    }
  },
  async mounted(){
    await this.$nextTick();
    if (this.path) {
      this.iframe_article_path = this.path;
      this.isExpanded = true;
    }
  },
  methods: {
    handleShow(){
      this.isExpanded = !this.isExpanded;
      this.$emit('toggle-iframe');
    },
    handleClose() {
      this.isExpanded = true;
      this.$emit('close-iframe');
    }
  }
}
</script>

<style>
.iframe-action {
  display: flex;
}

.iframe-actions {
  width: 100%;
  height: 48px;
  background-color: #1da1f2 !important;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.iframe-container {
  width: 100%;
  height: 100%;
}

.iframe-title {
  flex-grow: 1;
}

.iframe {
  width: 100%;
  height: 500px;
  border:none;
}

.iframe-shrinked {
  height: 0%;
  display: none;
}

.iframe-actions-sticky {
  position: fixed;
  bottom: 0;
  left: 0;
}

/* CSS pantallas de 320px o superior */
@media (min-width: 320px) {
  .iframe {
    width: 100%;
    height: 100%;
  }
}

/* CSS pantalla 768px o superior */
@media (min-width: 768px) {
  .iframe {
    width: 100%;
    height: 750px;
  }
}
</style>
