<template>
  <v-container class="pt-0 px-0">
    <v-radio-group v-model="finalAnswer">
      <v-radio
        v-for="(answer,i) in localAnswers"
        :key="i"
        :label="`${answer.description}`"
        :value="answer.index"
        :off-icon="`mdi-checkbox-blank-outline`"
        :on-icon="`mdi-checkbox-marked`"
        data-test-id="answerOption"
        @click="chooseAnswer(answer.index)"
      />
    </v-radio-group>
    <v-dialog
      v-model="showDialog"
      :max-width="dialogWidth"
      :persistent="!hasAnsweredCorrect"
    >
      <v-card
        :class="dialogClasses"
        data-test-id="feedbackModal"
      >
        <v-row
          justify="center"
          class="pt-5 no-gutters"
        >
          <v-col
            cols="5"
            class="text-center"
          >
            <div :class="dialogIconStyle">
              <uis-check
                v-show="hasAnsweredCorrect"
                size="24px"
              />
              <uis-exclamation-circle
                v-show="!hasAnsweredCorrect"
                size="24px"
              />
            </div>
          </v-col>
        </v-row>
        <v-card-title
          class="text text-m-large-bold dialog-text"
          data-test-id="feedbackAnswer"
        >
          {{ dialogTitle }}
        </v-card-title>

        <v-card-text class="text text-m-large-regular dialog-text">
          {{ dialogContent }}
        </v-card-text>

        <v-card-actions
          v-if="!hasAnsweredCorrect"
          class="pb-5"
        >
          <v-btn
            class="text-capitalize"
            elevation="0"
            color="primary"
            @click="handleTryAgain"
          >
            Try again
          </v-btn>
          <v-spacer />
          <v-btn
            class="text-capitalize"
            elevation="0"
            color="primary"
            data-test-id="nextQuestion"
            @click="nextQuestion"
          >
            Next
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { UisCheck, UisExclamationCircle } from '@iconscout/vue-unicons-solid';
export default {
  components: { UisCheck, UisExclamationCircle },
  props: ['answers', 'questionIndex', 'quizId', 'currentAnswer'],
  data: () => ({
    finalAnswer: null,
    localAnswers: [],
    showDialog: false,
    hasAnsweredCorrect: false,
  }),
  computed: {
    dialogClasses() {
      return this.hasAnsweredCorrect ? "theme-dialog success-dialog-size" : "theme-dialog error-dialog-size";
    },
    dialogTitle() {
      return this.hasAnsweredCorrect ? "It's correct" : "It's incorrect";
    },
    dialogContent() {
      return this.hasAnsweredCorrect ? "" : "You can continue to the next question or correct this answer";
    },
    dialogWidth() {
      return this.hasAnsweredCorrect ? 177 : 279;
    },
    dialogIconStyle() {
      return this.hasAnsweredCorrect ? "pa-3 correct-icon-style-answer":"pa-3 error-icon-style-answer";
    }
  },
  watch: {
    answers: function (newValue) {
      this.localAnswers = newValue;
      this.finalAnswer = null;

      if(this.currentAnswer !== -1) {
        this.finalAnswer = this.currentAnswer;
      }
    },
  },
  mounted() {
    this.localAnswers = this.answers;
  },
  methods: {
    ...mapActions('article', ['postQuizAnswer']),
    chooseAnswer(answerIndex) {
      this.postQuizAnswer({ id: this.quizId, questionIndex: this.questionIndex + 1, userAnswerIndex: answerIndex })
      .then( response => {
        this.showDialog = true;
        this.hasAnsweredCorrect  = response.data.success;
        this.$emit('answered', this.questionIndex, answerIndex, this.hasAnsweredCorrect);
      }).catch( error => {
        console.log(error);
      });
    },
    handleTryAgain() {
      this.showDialog = false;
      this.finalAnswer = null;
      this.$emit('try-again');
    },
    nextQuestion() {
      this.showDialog = false;
      this.$emit('next-question');
    }
  },
}
</script>

<style scoped>
.dialog-text {
  text-align: center;
  justify-content: center;
  color: #171725 !important;
}
.v-radio {
  background: #FFFFFF;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 14px !important;
}
.theme-dialog {
  background: #FFFFFF;
  box-shadow: none !important;
  border-radius: 15px !important;
}
.v-dialog {
  background: #FFFFFF;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12) !important;
  border-radius: 15px !important;
}
.correct-icon-style-answer {
  background: rgba(0, 230, 118, 0.15);
  color: #00E676;
  border-radius: 10px;
  width: 48px;
  height: 48px;
  margin: auto;
}
.error-icon-style-answer {
  background: rgba(255, 82, 82, 0.15);
  color: #FF5252;
  border-radius: 10px;
  width: 48px;
  height: 48px;
  margin: auto;
}
</style>
