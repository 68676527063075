<template>
  <div>
    <v-card-title class="text text-m-large-bold grey--text text--darken-1 pa-0 mb-1">Abstract</v-card-title>
    <v-card-text class="pa-0">
      <div
        class="abstract-box"
      >
        <v-row class="mt-1">
          <v-col
            cols="12"
            class="py-1 py-sm-0"
          >
            <p
              v-for="(abs, i) in article.article.abstract"
              :key="i"
              class="text text-m-large-regular grey--text text--darken-4"
            >
              <span
                v-if="abs.label !== null && abs.label !== ''"
                class="text text-l-small-bold"
              >{{ abs.label }}: </span>
              <span v-html="parseUtilities.richText(abs.text)" />
            </p>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import UtilitiesComponent from "@/components/common/UtilitiesComponent";
export default {
  props: ["article"],
  data: () => ({
    parseUtilities: UtilitiesComponent.data().parse
  }),
}
</script>
