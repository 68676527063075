<template>
  <div>
    <v-card-title class="text text-m-large-bold grey--text text--darken-1 pa-0 mb-1">
      <v-row justify="space-between">
        <v-col>
          Authors
        </v-col>
        <v-col class="text-right">
          <span
            v-if="$vuetify.breakpoint.xsOnly"
            class="heading headin-s-small clickable primary--text"
            @click="showAuthors"
          >See {{ authorsCount }} authors</span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text
      class="text text-l-large-regular grey--text text--darken-4 pa-0"
      :class="$vuetify.breakpoint.xsOnly ?'text-truncate':''"
    >
      {{ authors }}
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: ["article"],
  computed: {
    authors() {
      let authorsConcatenedText = "";

      authorsConcatenedText = this.article?.article?.authors.join(", ");

      return authorsConcatenedText;
    },
    authorsCount() {
      return this.article?.article?.authors?.length || 0;
    },
  },
  methods: {
    showAuthors() {
      this.$emit("show-authors");
    }
  }
}
</script>
