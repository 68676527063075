<template>
  <v-row
    no-gutters
    dense
    data-test-id="quizPanel"
  >
    <ProgressBarComponent
      v-if="quiz === null"
      :title="'quiz'"
    />
    <v-row
      v-if="!quizExist"
      class="fill-height"
      align-content="center"
      justify="center"
    >
      <p>Quiz not found</p>
    </v-row>
    <v-card
      v-if="quiz !==null && quizExist && article"
      class="mb-4 px-4 mt-sm-12 full-width-article height-100vh quiz-container"
      flat
    >
      <v-card-title class="py-2 px-0 pt-md-4">
        <v-icon
          class="clickable btn-close-mark"
          @click="$router.go(-1)"
        >
          mdi-close
        </v-icon>
        <div class="pl-3 text-left">
          {{ !hasFinishedQuiz ? "Quiz":"Quiz Results" }}
        </div>
      </v-card-title>
      <v-row
        no-gutters
        dense
        align="center"
        class="py-3 pb-0 mx-sm-15"
        justify="center"
      >
        <v-col class="col-12">
          <h6 class="heading heading-s-small text-left theme-content-article-title mb-0 mb-sm-0">
            {{ article.article.title }}
          </h6>
        </v-col>
        <v-col
          cols="12"
          class="line px-0 py-2"
        />
      </v-row>
      <v-row
        v-show="!hasFinishedQuiz"
        justify="center"
        class="mt-0 mx-sm-15"
      >
        <v-col
          cols="12"
          class="d-flex justify-center px-0"
        >
          <QuizChipListComponent
            v-for="(question,index) in quiz"
            :key="index"
            :quiz-index="question.index"
            :question-status="question.status"
            @click-dot="selectQuestion"
          />
        </v-col>
        <v-col
          cols="12"
          class="text-center px-0"
        >
          <QuizQuestionListComponent :title="getQuizTitle" />
        </v-col>
        <v-col
          v-show="showHint"
          class="col-11 col-sm-12"
        >
          <HintComponent :current-hint="currentHint" />
        </v-col>
        <v-col class="col-11 col-sm-12 d-flex pb-0 px-md-0">
          <v-btn
            v-show="!isReported"
            class="text text-l-small-regular btn-report-question text-capitalize ml-auto mr-0 px-3"
            elevation="0"
            color="light"
            @click="reportQuestion"
          >
            Report question
          </v-btn>
          <span
            v-show="isReported"
            class="ml-auto mr-0"
          >Your report has been submitted.</span>
          <ReportComponent
            :show-dialog-prop="showReportDialog"
            :options="'test'"
            @close-report-dialog="showReportDialog = !showReportDialog"
            @submit-report-issue="submitReportIssue"
          />
        </v-col>
        <v-col
          cols="12"
          class="answer-container pt-0 px-md-0"
        >
          <QuizAnswerListComponent
            :answers="answers"
            :question-index="currentQuestionIndex"
            :quiz-id="quizId"
            :current-answer="currentAnswer"
            @answered="updateQuestionStatus"
            @next-question="nextQuestion"
            @try-again="tryAgain"
          />
        </v-col>
      </v-row>
      <v-row
        v-show="hasFinishedQuiz"
        class="mt-3"
      >
        <v-col cols="12">
          <ResultsComponent
            :quiz="quiz"
            :credits="credits"
            :article-specialties="article.specialties"
            :can-change-answers="true"
            :enable-go-to-feed="true"
            @change-answer="changeAnswer"
          />
        </v-col>
      </v-row>
      <v-card-actions class="px-0 mx-sm-15">
        <v-btn
          v-show="!hasFinishedQuiz"
          class="btn-read-article text-capitalize "
          elevation="0"
          color="primary"
          @click="readArticle"
        >
          <ReadArticleIconComponent left />
          &nbsp; Read article
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>

<script>
import ProgressBarComponent from "@/components/common/ProgressBarComponent";
import ReadArticleIconComponent from "@/assets/ReadArticleIconComponent";
// eslint-disable-next-line no-unused-vars
import { mapActions } from "vuex";
import QuizChipListComponent from '@/components/quiz/QuizChipListComponent';
import QuizQuestionListComponent from '@/components/quiz/QuizQuestionListComponent';
import QuizAnswerListComponent from '@/components/quiz/QuizAnswerListComponent';
import HintComponent from '@/components/quiz/HintComponent';
import ReportComponent from '@/components/quiz/ReportComponent';
const ResultsComponent = () => import(/* webpackChunkName: "ResultsComponent" */ '@/components/quiz/ResultsComponent');

export default {
  components: {
    ProgressBarComponent,
    ReadArticleIconComponent,
    QuizChipListComponent,
    QuizQuestionListComponent,
    QuizAnswerListComponent,
    HintComponent,
    ReportComponent,
    ResultsComponent,
  },
  props: ['article'],
  data() {
    return {
      loading: true,
      quiz: null,
      credits: 0,
      quizExist: true,
      currentQuestionIndex: 0,
      hasFinishedQuiz: false,
      showHint: false,
      showReportDialog: false,
    }
  },
  computed: {
    getQuizTitle() {
      return this.quiz[this.currentQuestionIndex]?.question ?? "Loading...";
    },
    answers() {
      return this.quiz[this.currentQuestionIndex]?.answers ?? [];
    },
    currentAnswer() {
      return this.quiz[this.currentQuestionIndex]?.currentAnsweredIndex ?? -1;
    },
    currentHint() {
      return this.quiz[this.currentQuestionIndex]?.hint ?? 'No hint available';
    },
    isReported() {
      return this.quiz[this.currentQuestionIndex].reported;
    }
  },
  methods: {
    ...mapActions('article', ['fetchArticleQuiz']),
    ...mapActions('issue', ['postReportQuiz']),
    closeQuiz() {
      this.$router.push({ name: 'article' , params: { id: this.$route.params.id }});
    },
    readArticle() {
      this.$emit('read-article');
    },
    selectQuestion(index) {
      this.showHint = false;
      this.currentQuestionIndex = index;

      this.quiz = this.quiz.map( question => {

        if (question.answered) {
            return question;
        }

        question.status = question.index === index ? "current" : "default";

        return question;
      });
    },
    updateQuestionStatus(index, answerIndex, result) {
      this.quiz = this.quiz.map( question => {
        if(question.index == index) {
          question.status = result ? "success" : "failure";
          question.answered = true;
          question.currentAnsweredIndex = answerIndex;
        }
        return question;
      });

      this.hasFinishedQuiz = this.quiz.every( question => question.answered );
    },
    nextQuestion() {
      if(!this.hasFinishedQuiz) {
        if(this.currentQuestionIndex < this.quiz.length) {
          this.selectQuestion(this.currentQuestionIndex+1);
        }
        if(this.currentQuestionIndex >= this.quiz.length) {

          let nextNotAnsweredQuestionIndex = this.quiz.findIndex( quiz => !quiz.answered );

          if (nextNotAnsweredQuestionIndex !== -1) {
            this.selectQuestion(this.quiz[nextNotAnsweredQuestionIndex].index);
          }
        }
      }
    },
    tryAgain() {
      this.showHint = true;
    },
    reportQuestion() {
      this.showReportDialog = !this.showReportDialog;
    },
    changeAnswer(questionIndex) {
      this.hasFinishedQuiz = false;
      this.selectQuestion(questionIndex);
    },
    submitReportIssue(reasons) {

      this.postReportQuiz({ modelId: this.quizId, details: { questionIndex: this.currentQuestionIndex }, reasons: reasons })
      .then( response => {

        this.quiz = this.quiz.map( question => {
          if (question.index == this.currentQuestionIndex) {
            question.reported = true;
          }
          return question;
        });

        this.$toasted.global.showSuccess({message: response.data.message});
      }).catch( error => {
        this.$toasted.global.showError({message:  error.response.data.errors  ? error.response.data.errors[0].message  :error.response.data ?  error.response.data.message:"Unexpected error"});
      });

      this.showReportDialog = !this.showReportDialog;
    }
  },
  async mounted() {
    await this.fetchArticleQuiz({ params: { id: this.$route.params.id }}).then( response => {
      this.quizId = response.data.quizId;
      this.quiz = response.data.questions;
      this.credits = response.data.credits;

      this.quiz = response.data.questions.map( (question,index) => {
        question.index = index;
        question.answered = false;
        question.currentAnsweredIndex = -1;
        question.reported = false;
        return question;
      });

      this.quiz[0].status = "current";

    }).catch( error => {
      console.log(error);
    });
  }
}
</script>
<style>
@media (min-width: 768px) {
  div.v-card.quiz-container {
    border-radius: 20px;
  }
}
.theme-content-article-title {
  font-weight: normal !important;
  color: #44444F;
}
.answer-container {
  height: 420px;
}
.btn-read-article {
  position: sticky;
  bottom: 10px;
}
.btn-take-article {
  position: sticky;
  bottom: 10px;
  left: 160px;
}
.height-100vh {
  height: 100vh;
}
.btn-report-question span {
  text-transform: uppercase !important;
  color: #424242 !important;
}
.btn-report-question {
  background: #F5F5F5;
  border-radius: 10px;
}
.line {
  border-bottom: 1px solid #ECEFF1;
}
</style>
