<template>
  <div>
    <v-card-title class="pa-0 mb-1">
      <v-row
        no-gutters
        justify="space-between"
        dense
      >
        <v-col class="col-auto mr-2 mr-lg-3">
          <p
            class="text text-m-large-regular grey--text text--darken-1 word-wrap-all mb-1"
          >
            {{ article.article.journal }}
          </p>
        </v-col>
        <v-col class="col-auto d-flex" />
      </v-row>
      <p
        class="article-title heading text-left grey--text text--darken-4 word-wrap-all my-2"
        :class="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 'text-l-large-semibold' : 'heading-s-large' "
        v-html="parseUtilities.richText(article.article.title)"
      />
    </v-card-title>
    <v-card-actions class="px-0 pb-0 small-mobile">
      <VoteArticleComponent
        class="votes-to-right"
        :class="AddClassToMoveIfDownvotesAreTooBig"
        :initial-votes="article.diffVotes"
        :initial-upvotes="article.upVotes"
        :initial-downvotes="article.downVotes"
        :user-vote="article.voteStatus"
        :article-id="article.id"
      />
    </v-card-actions>
  </div>
</template>

<script>
import VoteArticleComponent from "@/components/common/VoteArticleComponent";
import UtilitiesComponent from "@/components/common/UtilitiesComponent";
export default {
  components: {
    VoteArticleComponent,
  },
  props: ["article"],
  data: () => ({
    parseUtilities: UtilitiesComponent.data().parse,
    MAX_DOWN_VOTES_CHARS_ALLOWED_WITHOUT_DISPLACE: 4
  }),
  computed: {
    AddClassToMoveIfDownvotesAreTooBig() {

      let formatter = Intl.NumberFormat('en', { notation: 'compact', minimumSignificantDigits: 1, maximumSignificantDigits: 3 });
      let result = formatter.format(this.article.downVotes);

      if ( result.toString().length < this.MAX_DOWN_VOTES_CHARS_ALLOWED_WITHOUT_DISPLACE) {
        return "votes-to-right-no-diff";
      }

      return "";
    }
  },
}
</script>

<style scoped>
.article-title {
  line-height: 23px;
}
.votes-to-right {
  margin-left: auto;
}
.votes-to-right-no-diff {
  margin-right: -20px;
}

@media (max-width: 320px) {
  .small-mobile {
    justify-content: center;
  }

  .votes-to-right {
    margin-left: auto;
  }
  
  .votes-to-right-no-diff {
    margin-right: -20px;
  }
}

</style>
