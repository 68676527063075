<template>
  <div>
    <v-row
      v-if="!articleExist"
      class="fill-height"
      align-content="center"
      justify="center"
    >
      <p>Article not found</p>
    </v-row>
    <div v-if="article !== null && articleExist">
      <!-- Tablets and mobile -->
      <v-row
        v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
        class="mt-3"
      >
        <v-col cols="12">
          <v-card
            class="card-shadow border-radius-general pt-5 px-4 pb-4"
            color="grey lighten-5"
            flat
          >
            <JournalTitleAndVotesComponent :article="article" />
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card
            class="card-shadow border-radius-small pt-5 px-4 pb-4"
            color="grey lighten-5"
            flat
          >
            <AuthorsComponent
              :article="article"
              @show-authors="showAuthors"
            />
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card
            class="card-shadow border-radius-small pt-5 px-4 pb-4"
            color="grey lighten-5"
            flat
          >
            <v-card-title class="text text-m-large-bold grey--text text--darken-1 pa-0 mb-1">
              Specialties
            </v-card-title>
            <v-card-text class="pa-0">
              <SpecialtiesComponent
                :specialties="specialtiesTitles"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card
            class="card-shadow border-radius-small pt-5 px-4 pb-4"
            color="grey lighten-5"
            flat
          >
            <InterestsComponent
              :article="article"
              @show-interests="showInterests"
            />
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card
            class="card-shadow border-radius-small pt-5 px-4 pb-4"
            color="grey lighten-5"
            flat
          >
            <AbstractComponent :article="article" />
          </v-card>
        </v-col>
      </v-row>
      <!-- Small desktops -->
      <v-row
        v-if="$vuetify.breakpoint.mdOnly"
        class="mt-6"
      >
        <v-col cols="12">
          <v-card
            class="card-shadow border-radius-general pt-5 px-4 pb-4"
            color="grey lighten-5"
            flat
          >
            <JournalTitleAndVotesComponent :article="article" />
          </v-card>
        </v-col>
        <v-col cols="7">
          <v-card
            class="card-shadow border-radius-small mb-6 pt-5 px-4 pb-4"
            color="grey lighten-5"
            flat
          >
            <AuthorsComponent :article="article" />
          </v-card>
          <v-card
            class="card-shadow border-radius-small mb-6 pt-5 px-4 pb-4"
            color="grey lighten-5"
            flat
          >
            <AbstractComponent :article="article" />
          </v-card>
        </v-col>
        <v-col cols="5">
          <v-card
            class="card-shadow border-radius-small mb-6 pt-5 px-4 pb-4"
            color="grey lighten-5"
            flat
          >
            <v-card-title class="text text-m-large-bold grey--text text--darken-1 pa-0 mb-1">
              Specialties
            </v-card-title>
            <v-card-text class="pa-0">
              <SpecialtiesComponent
                :specialties="specialtiesTitles"
              />
            </v-card-text>
          </v-card>
          <v-card
            class="card-shadow border-radius-small mb-6 pt-5 px-4 pb-4"
            color="grey lighten-5"
            flat
          >
            <InterestsComponent
              :article="article"
              @show-interests="showInterests"
            />
          </v-card>
        </v-col>
      </v-row>
      <!-- Large desktops and up -->
      <v-row
        v-if="$vuetify.breakpoint.lgAndUp"
        class="mt-6"
      >
        <v-col cols="8">
          <v-card
            class="card-shadow border-radius-general mb-6 pt-5 px-4 pb-4"
            color="grey lighten-5"
            flat
          >
            <JournalTitleAndVotesComponent :article="article" />
          </v-card>
          <v-card
            class="card-shadow border-radius-small mb-6 pt-5 px-4 pb-4"
            color="grey lighten-5"
            flat
          >
            <AuthorsComponent :article="article" />
          </v-card>
          <v-card
            class="card-shadow border-radius-small mb-6 pt-5 px-4 pb-4"
            color="grey lighten-5"
            flat
          >
            <AbstractComponent :article="article" />
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card
            class="card-shadow border-radius-small mb-6 pt-5 px-4 pb-4"
            color="grey lighten-5"
            flat
          >
            <v-card-title class="text text-m-large-bold grey--text text--darken-1 pa-0 mb-1">
              Specialties
            </v-card-title>
            <v-card-text class="pa-0">
              <SpecialtiesComponent
                :specialties="specialtiesTitles"
              />
            </v-card-text>
          </v-card>
          <v-card
            class="card-shadow border-radius-small mb-6 pt-5 px-4 pb-4"
            color="grey lighten-5"
            flat
          >
            <InterestsComponent
              :article="article"
              @show-interests="showInterests"
            />
          </v-card>
        </v-col>
      </v-row>
      <!-- Tablets and mobiles sticky popups -->
      <div>
        <DialogMobileComponent
          v-if="isShowingAuthors"
          :title="'Authors'"
          :content="authors"
          :use-slot="false"
          @close-dialog="closeDialog"
        />
        <DialogMobileComponent
          v-if="isShowingInterests"
          :title="'Interests'"
          :content="null"
          :use-slot="true"
          @close-dialog="closeDialog"
        >
          <template #content>
            <KeywordsCollapsibleComponent
              :key="uniqueKeysHelper()"
              class="mx-sm-15"
              :keywords="article.keywords"
              :disable-accordion="true"
              @reload-feed="$emit('reload-feed')"
            />
          </template>
        </DialogMobileComponent>
      </div>
    </div>
  </div>
</template>

<script>
import JournalTitleAndVotesComponent from "@/components/article/JournalTitleAndVotesComponent";
import AuthorsComponent from "@/components/article/AuthorsComponent";
import InterestsComponent from "@/components/article/InterestsComponent";
import AbstractComponent from "@/components/article/AbstractComponent";
import KeywordsCollapsibleComponent from "@/components/feed/KeywordsCollapsibleComponent";
import SpecialtiesComponent from "@/components/common/SpecialtiesComponent";
import DialogMobileComponent from "@/components/common/DialogMobileComponent";
import UtilitiesComponent from "@/components/common/UtilitiesComponent";
import { v4 as uuidv4 } from 'uuid';
import { mapActions } from 'vuex';
export default {
  components: {
    JournalTitleAndVotesComponent,
    AuthorsComponent,
    InterestsComponent,
    AbstractComponent,
    DialogMobileComponent,
    KeywordsCollapsibleComponent,
    SpecialtiesComponent,
    // eslint-disable-next-line vue/no-unused-components
    UtilitiesComponent,
  },
  props: ['article', 'articleExist'],
  data: () => ({
    isShowingAuthors: false,
    isShowingInterests: false,
    uniqueKeysHelper: uuidv4,
    parseUtilities: UtilitiesComponent.data().parse
  }),
  computed: {
    specialtiesTitles() {
      return this.article?.specialties.map( specialty => specialty.title );
    },
    urlToShareArticle() {
      return process.env.VUE_APP_URL + '/article/' + this.article.id;
    },
    authors() {
      let authorsConcatenedText = "";

      authorsConcatenedText = this.article?.article?.authors.join(", ");

      return authorsConcatenedText;
    },
  },
  methods: {
    ...mapActions("menu", ["setGeneralVeil"]),
    showAuthors() {
      this.isShowingAuthors = true;
      this.setGeneralVeil(true);
    },
    showInterests() {
      this.isShowingInterests = true;
      this.setGeneralVeil(true);
    },
    closeDialog() {
      this.isShowingAuthors = false;
      this.isShowingInterests = false;
      this.setGeneralVeil(false);
    },
  }
}
</script>
<style scoped>
.article-actions {
  bottom: 0px;
  position: sticky;
  padding-top: 150px;
}
.wrapper-component-fixed {
  position: fixed;
  bottom: 0;
}
</style>
