<template>
  <div>
    <p class="text text-l-large-regular">
      {{ title }}
    </p>
  </div>
</template>

<script>
export default {
  props: ['title']
}
</script>

