<template>
  <div :class="getIframeContainerClass">
    <div :class="getIframeActionsClass">
      <h1 class="text text-l-large-bold grey--text text--darken-1 iframe-title">
        {{ getIframeTitle }}
      </h1>
      <button
        class="iframe-action"
        @click="handleClose"
      >
        <CloseIconComponent />
      </button>
    </div>
    <!-- content here -->
    <div v-if="!useSlot" class="iframe pa-6">
      {{ content }}
    </div>
    <div v-if="useSlot" class="iframe pa-6">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import CloseIconComponent from "@/assets/CloseIconComponent";
export default {
  components: { CloseIconComponent },
  props: ["title", "content", "useSlot"],
  data: () => ({
		isExpanded: true
  }),
  computed: {
    getIframeTitle() {
      return this.title;
    },
    getIframeContainerClass() {
      return this.isExpanded ? 'iframe-container' : '';
    },
    getIframeActionsClass() {
      return this.isExpanded ? 'iframe-actions px-6 pt-7': 'iframe-actions iframe-actions-sticky';
    }
  },
  async mounted(){
  },
  methods: {
    handleClose() {
      this.isExpanded = true;
      this.$emit('close-dialog');
    }
  }
}
</script>

<style>
.iframe-action {
  display: flex;
}

.iframe-actions {
  width: 100%;
  height: 48px;
  background-color: var(--v-background-base) !important;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.iframe-container {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  height: auto;
  z-index: 5;
  background-color: var(--v-background-base) !important;
  border-radius: 24px 24px 0px 0px;
}

.iframe-title {
  flex-grow: 1;
}

.iframe {
  width: 100%;
  height: 500px;
  border:none;
}

.iframe-shrinked {
  height: 0%;
  display: none;
}

.iframe-actions-sticky {
  position: fixed;
  bottom: 0;
  left: 0;
}

/* CSS pantallas de 320px o superior */
@media (min-width: 320px) {
  .iframe {
    width: 100%;
    height: 100%;
  }
}

/* CSS pantalla 768px o superior */
@media (min-width: 768px) {
  .iframe {
    width: 100%;
    height: 750px;
  }
}
</style>
