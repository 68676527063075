<template>
  <div
    :class="getDotStyle"
    class="text text-m-small-regular mx-2 theme-quiz-chip-list text-center clickable"
    data-test-id="questionChipList"
    @click="clickDot"
  >
    <span>
      {{ quizIndex + 1 }}
    </span>
  </div>
</template>

<script>
export default {
  props: ['quiz-index', 'question-status'],
  computed: {
    getDotStyle() {
      return this.questionStatus ? `theme-quiz-chip-${this.questionStatus}` : "theme-quiz-chip-default";
    }
  },
  methods: {
    clickDot() {
      this.$emit('click-dot', this.quizIndex);
    }
  }
}
</script>

<style scoped>
.theme-quiz-chip-list {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 2px solid;
}
@media (min-width: 768px) {
  .theme-quiz-chip-list {
    width: 30px;
    height: 30px;
  }
}
.theme-quiz-chip-list span {
  margin: auto;
}
.theme-quiz-chip-success {
  background-color: #2196F3;
  border-color: #2196F3;
  color: #FFFFFF;
}
.theme-quiz-chip-failure {
  background-color: #FF5252;
  border-color: #FF5252;
  color: #FFFFFF;
}
.theme-quiz-chip-current {
  background-color: #FFFFFF;
  border-color: #2196F3;
  color: #000000;
}
.theme-quiz-chip-default {
  background-color: #FFFFFF;
  border-color: #90A4AE;
  color: #000000;
}
.clickable:hover {
  cursor: pointer;
}
</style>
