<template>
  <v-dialog
    v-model="showDialog"
    :max-width="279"
    persistent
  >
    <v-card class="theme-dialog">
      <v-row
        justify="center"
        class="pt-5 no-gutters"
      >
        <v-col
          cols="5"
          class="text-center"
        >
          <div class="pa-3 mt-0 warning-icon-style-answer">
            <v-icon class="warning-icon-text-color">
              mdi-alert-circle-outline
            </v-icon>
          </div>
        </v-col>
      </v-row>
      <v-card-title class="text text-m-large-bold dialog-text">
        Report this question
      </v-card-title>

      <v-card-text class="text text-m-large-regular dialog-text">
        <v-container>
          <v-radio-group v-model="reportChoice">
            <v-radio
              v-for="(option,i) in reportChoices"
              :key="i"
              :label="`${option}`"
              :value="option"
              :off-icon="`mdi-checkbox-blank-outline`"
              :on-icon="`mdi-checkbox-marked`"
            />
          </v-radio-group>
        </v-container>
      </v-card-text>

      <v-card-actions class="pb-5">
        <v-btn
          class="text-capitalize"
          elevation="0"
          color="default"
          @click="cancel"
        >
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          class="text-capitalize"
          elevation="0"
          color="primary"
          @click="submitReportIssue"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['showDialogProp','options'],
  data: () => ({
    showDialog: false,
    reportChoice: null,
    reportChoices: ['Poorly written question', 'Disputed correct answer', 'Answers were not relevant to the article'],
  }),
  watch: {
    showDialogProp: function () {
      this.showDialog = !this.showDialog;
    }
  },
  methods: {
    cancel() {
      this.$emit('close-report-dialog');
    },
    submitReportIssue() {
      this.$emit('submit-report-issue', this.reportChoice);
    }
  }
}
</script>

<style scoped>
.dialog-text {
  text-align: center;
  justify-content: center;
  color: #171725 !important;
}
.v-radio {
  background: #FFFFFF;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  padding: 14px !important;
}
.theme-dialog {
  background: #FFFFFF;
  box-shadow: none !important;
  border-radius: 15px !important;
}
.v-dialog {
  background: #FFFFFF;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12) !important;
  border-radius: 15px !important;
}
.warning-icon-style-answer {
  background: #FDF7D7;
  border-radius: 10px;
  width: 48px;
  height: 48px;
  margin: auto;
}
.warning-icon-text-color {
  color: #F6D000 !important;
}
</style>