<template>
  <div class="container hint-box">
    <v-row>
      <v-col
        cols="12"
        class="d-flex"
      >
        <div class="pa-3 mt-0 error-icon-style-answer">
          <v-icon class="error-icon-text-color">
            mdi-alert-circle-outline
          </v-icon>
        </div>
        <div class="text text-s-small-regular px-3 py-1 hint-text">
          {{ currentHint }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['currentHint'],
}
</script>

<style scoped>
.hint-box {
  background: #F5F5F5;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12) !important;
  border-radius: 15px !important;
}
.hint-text {
  color: #44444F;
}
.error-icon-style-answer {
  background: rgba(255, 82, 82, 0.15);
  border-radius: 10px;
  width: 48px;
  height: 48px;
}
.error-icon-text-color {
  color: #FF5252 !important;
}
</style>