<template>
  <v-row
    no-gutters
    dense
    justify="center"
  >
    <ProgressBarComponent
      v-show="article === null"
      :title="'article'"
    />
    <div
      v-show="article !== null"
      class="col-12"
    >
      <ArticleCardComponent
        v-if="$route.name === 'article'"
        :article="article"
        :article-exist="articleExist"
        :current-user="currentUser"
        @read-article="readArticle"
        @take-quiz="takeQuiz"
      />
      <QuizComponent
        v-if="$route.name === 'article-quiz'"
        :article="article"
        @read-article="readArticle"
      />
    </div>
    <!-- Fixed Get CME button -->
    <v-row v-if="$route.name === 'article' && $vuetify.breakpoint.xsOnly && article !== null" class="get-cme my-3">
      <v-col cols="12">
        <v-btn
          ripple
          block
          large
          class=" heading heading-s-small white--text text-capitalize shadow-dark-button"
          :class="'cold-gradient'"
          data-test-id="submit"
          @click="takeQuiz"
        >
          Get CME
        </v-btn>
      </v-col>
    </v-row>
    <v-card
      v-show="showIframeArticle"
      :class="getIframeWrapperClass"
      flat
    >
      <a
        ref="article_link"
        class="d-none"
        :href="getRedirectLink"
        target="_blank"
        rel="noopener"
      />
      <IframeArticleComponent
        v-if="article !== null"
        :show="showIframeArticle"
        :path="iframeSource"
        @toggle-iframe="handleToggleIframe"
        @close-iframe="handleClose"
      />
    </v-card>
  </v-row>
</template>

<script>
import ProgressBarComponent from "@/components/common/ProgressBarComponent";
import { mapActions, mapGetters } from "vuex";
import IframeArticleComponent from "@/components/common/IframeArticleComponent";
import ArticleCardComponent from "@/components/article/ArticleCardComponent";
import QuizComponent from "@/components/quiz/QuizComponent";
export default {
  name: "ViewArticle",
  components: {
    ProgressBarComponent,
    IframeArticleComponent,
    ArticleCardComponent,
    QuizComponent,
  },
  data() {
    return {
      loading: true,
      articleExist: true,
      article: null,
      iframeSource: null,
      showIframeArticle: false,
      showVeil: false,
      iframeShrinked: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['currentUser']),
    getIframeWrapperClass () {
      return !this.iframeShrinked ? "my-4 full-width-article border-radius-top iframe-wrapper-component": "border-radius-top iframe-wrapper-component-sticky";
    },
    getRedirectLink() {
      return this.article !== null ? this.article.article.file.external_path : "#";
    }
  },
  mounted() {
    this.loadArticle();
  },
  methods: {
    ...mapActions('article', ['fetchArticle']),
    loadArticle() {
      const articleId = this.$route.params.id;
      this.fetchArticle(articleId).then(article => {
        this.article = article;
        this.iframeSource = this.article.article.file.pmc_path;
        this.articleExist = !!this.article.id;
      }).catch(error => {
        console.log(error);
      });
    },
    readArticle() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      if(this.iframeSource === null){
        this.$refs.article_link.click();
      }
      this.showIframeArticle = this.iframeSource !== null;
      this.showVeil = this.iframeSource !== null;
    },
    handleToggleIframe() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.iframeShrinked = !this.iframeShrinked;
      this.showVeil = !this.showVeil;
    },
    handleClose() {
      this.showIframeArticle = false;
      this.iframeShrinked = false;
      this.showVeil = false;
    },
    takeQuiz() {
      this.$router.push({ name: "article-quiz", params: { id: this.$route.params.id }});
    },
    hideDialog() {
      this.showVeil = false;
    }
  }
}
</script>

<style>
.keywords-box {
  border: 1px solid rgba(193, 193, 217, 0.27);
  box-sizing: border-box;
  border-radius: 5px;
}
.authors-box {
  border: 1px solid rgba(193, 193, 217, 0.27);
  box-sizing: border-box;
  border-radius: 5px;
  background-color: #FAFAFA;
}

.abstract-box-collapsed {
  margin: 10px 0px;
  max-height: 20%;
  overflow: hidden;
  transition: max-height 500ms, transform 2s;
}

.abstract-box {
  margin: 0px 0px;
  max-height: 100%;
  transition: max-height 500ms, transform 2s;
  overflow: hidden;
}

.head-panel-title {
  color: #37474F !important;
}

.btn-close-mark {
  color: #B0BEC5 !important;
}

@media (min-width: 360px) and (max-height: 640px) {
  .abstract-box-collapsed {
    max-height: 180px;
  }
  .car-cont {
    max-height: 600px
  }
}

@media (min-width: 375px) and (max-height: 667px) {
  .abstract-box-collapsed {
    max-height: 180px;
  }
  .car-cont {
    max-height: 640px
  }
}

@media (min-width: 320px) and (max-height: 568px) {
  .abstract-box-collapsed {
    max-height: 180px;
  }

  .car-cont {
    max-height: 300px
  }
}

@media (min-width: 375px) and (max-height: 667px) {
  .abstract-box-collapsed {
    max-height: 180px;
  }
  .car-cont {
    max-height: 620px
  }
}

@media (min-width: 411px) and (max-height: 823px) {
  .abstract-box-collapsed {
    max-height: 180px;
  }
  .car-cont {
    max-height: 690px
  }
}

@media (min-width: 411px) and (height: 823px) {
  .abstract-box-collapsed {
    max-height: 180px;
  }
  .car-cont {
    max-height: 690px
  }
}

@media (min-width: 375px) and (height: 812px) {
  .abstract-box-collapsed {
    max-height: 180px;
  }
  .car-cont {
    max-height: 690px
  }
}

@media (min-width: 768px) {
  div.v-card.car-cont {
    border-radius: 20px;
  }
}

.full-width-article {
  width: 100%;
}

.border-radius-top {
  border-radius: 10px 10px 0px 0px;
}

.iframe-wrapper-component {
  position: absolute;
  z-index: 5;
  top: 0px;
  height: 100%;
  border-radius: 10px !important;
}

.iframe-wrapper-component-sticky {
  position: fixed;
  bottom: 0;
}

.get-cme {
  bottom: 20px;
  position: sticky;
}
</style>
