<template>
  <div>
    <v-card-title class="text text-m-large-bold grey--text text--darken-1 pa-0 mb-1">
      <v-row justify="space-between">
        <v-col>
          Interests
        </v-col>
        <v-col class="text-right">
          <span
            v-if="checkHasToShowInterestLink()"
            class="heading headin-s-small clickable primary--text"
            @click="showInterests"
          >See {{ interestsCount }} interests</span>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text class="text text-l-large-regular grey--text text--darken-4 pa-0">
      <KeywordsCollapsibleComponent
        :key="uniqueKeysHelper()"
        :keywords="keywordsPreview"
        :disable-accordion="true"
        :list-mode="$vuetify.breakpoint.mdAndUp"
        @reload-feed="$emit('reload-feed')"
      />
    </v-card-text>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import KeywordsCollapsibleComponent from "@/components/feed/KeywordsCollapsibleComponent";
export default {
  components: {
    KeywordsCollapsibleComponent
  },
  props: ["article"],
  data: () => ({
    uniqueKeysHelper: uuidv4,
    MAXIMUM_KEYWORDS_TO_SHOW_IN_MOBILE_PREVIEW: 5,
    MAXIMUM_KEYWORDS_TO_SHOW_LINK: 5
  }),
  computed: {
    keywordsPreview() {

      if(!this.article?.keywords) {
        return [];
      }

      return this.$vuetify.breakpoint.xsOnly ? this.article?.keywords.slice(0, this.MAXIMUM_KEYWORDS_TO_SHOW_IN_MOBILE_PREVIEW) : this.article?.keywords;
    },
    interestsCount() {
      return this.article?.keywords?.length || 0;
    }
  },
  methods: {
    showInterests() {
      this.$emit("show-interests");
    },
    checkHasToShowInterestLink() {
      return this.$vuetify.breakpoint.xsOnly && this.interestsCount > this.MAXIMUM_KEYWORDS_TO_SHOW_LINK;
    },
  }
}
</script>
